import { Notyf } from "notyf";
import { useEffect, useState } from "react";

export const useToast = () => {
  const [toast, setToast] = useState<any>(null);

  useEffect(() => {
    const _toast = new Notyf({
      duration: 3000,
      position: { x: "center", y: "top" },
    });
    setToast(_toast);
  }, []);

  return toast;
};
