import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactNode } from "react";
import {
  Bars3Icon,
  BuildingOfficeIcon,
  FolderIcon,
  HomeIcon,
  TableCellsIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
  ClipboardDocumentListIcon,
  GlobeAsiaAustraliaIcon,
  ChartPieIcon,
  ClipboardDocumentCheckIcon,
  ArrowLeftOnRectangleIcon,
  NewspaperIcon,
  QuestionMarkCircleIcon,
  ChevronRightIcon,
  CogIcon,
  UserIcon,
  WalletIcon,
  StarIcon,
  UserPlusIcon,
  BookOpenIcon,
  UserGroupIcon,
  ChatBubbleLeftRightIcon,
  AcademicCapIcon,
  HandThumbUpIcon,
  CalendarDaysIcon,
  ClipboardDocumentIcon,
  TrophyIcon,
  InboxStackIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCurrentUser } from "@/hoc/useCurrentUser";
import Image from "next/image";
import css from "./AppLayout.module.scss";
import { useToast } from "@/utils";
import { newAuthRepository } from "@/repositories/auth";
import { client } from "@/api/client";
import { useSetRecoilState } from "recoil";
import { currentUserState } from "@/states/currentUser";
import { Disclosure } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Navigation = {
  name: string;
  href: string;
  current: boolean;
  icon: any;
  children?: Navigation[] | undefined;
  roles: ("owner" | "member" | "manager" | undefined)[];
  plans: (
    | "free"
    | "single"
    | "multiple"
    | "entry"
    | "standard"
    | "advance"
    | undefined
  )[];
};

export const AppLayout = ({ children }: { children: ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState<Navigation[]>([
    {
      name: "マイページTOP",
      href: "/app",
      icon: HomeIcon,
      current: true,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "タスク管理",
      href: "/app/action_plans",
      icon: ClipboardDocumentCheckIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "経営の最新情報",
      href: "/app/management_information",
      icon: NewspaperIcon,
      current: false,
      roles: ["owner", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "戦略見える化シート",
      href: "/app/management_design_sheet",
      icon: TableCellsIcon,
      current: false,
      roles: ["owner"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "PDCAシート",
      href: "/app/pdca_sheets",
      icon: TableCellsIcon,
      current: false,
      roles: ["owner"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "学習コンテンツ",
      href: "/app/tactic_primaries",
      icon: FolderIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
      children: [
        {
          name: "学習コンテンツ",
          href: "/app/tactics/categories",
          icon: BookOpenIcon,
          current: false,
          roles: ["owner", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "受講グループ",
          href: "/app/course_group_tactics",
          icon: AcademicCapIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "受講グループ作成・編集",
          href: "/app/course_groups",
          icon: FolderIcon,
          current: false,
          roles: ["owner", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "受講グループ割り当て",
          href: "/app/user_course_groups",
          icon: InboxStackIcon,
          current: false,
          roles: ["owner", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "分析・学習ツール",
          href: "/app/frameworks",
          icon: WrenchScrewdriverIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
      ],
    },
    {
      name: "ダッシュボード",
      href: "/app/dashboards",
      icon: ChartPieIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
      children: [
        {
          name: "全講座受講状況",
          href: "/app/dashboards",
          icon: ChartPieIcon,
          current: false,
          roles: ["owner", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "経験値ランキング",
          href: "/app/rankings/private",
          icon: StarIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
      ],
    },
    {
      name: "AIアシスタント",
      href: "/app/gpt_apps",
      icon: SparklesIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "カスタムフォーム",
      href: "/app/surveys",
      icon: ClipboardDocumentListIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "いつでもチャット",
      href: "/app/online_consultation",
      icon: ChatBubbleLeftRightIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "評価シート",
      href: "/app/evaluation_periods",
      icon: HandThumbUpIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
      children: [
        {
          name: "評価入力・評価結果",
          href: "/app/evaluation_periods",
          icon: CalendarDaysIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "評価テンプレート編集",
          href: "/app/evaluation_templates",
          icon: ClipboardDocumentIcon,
          current: false,
          roles: ["owner", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "評価ランク編集",
          href: "/app/evaluation_ranks",
          icon: TrophyIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
      ],
    },
    {
      name: "社員登録",
      href: "/organization",
      icon: UserGroupIcon,
      current: false,
      roles: ["owner", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
      children: [
        {
          name: "社員招待",
          href: "/invitation/new",
          icon: UserPlusIcon,
          current: false,
          roles: ["owner", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "社員一覧",
          href: "/app/users",
          icon: UsersIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
      ],
    },
    {
      name: "設定",
      href: "/organization",
      icon: CogIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
      children: [
        {
          name: "企業情報",
          href: "/organization",
          icon: BuildingOfficeIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "会員情報",
          href: "/mypage",
          icon: UserIcon,
          current: false,
          roles: ["owner", "member", "manager"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
        {
          name: "プラン確認",
          href: "/billing",
          icon: WalletIcon,
          current: false,
          roles: ["owner"],
          plans: ["free", "single", "multiple", "entry", "standard", "advance"],
        },
      ],
    },
  ]);
  const [subNavigation, setSubNavigation] = useState<Navigation[]>([
    {
      name: "戦略ラボTOP",
      href: "/",
      icon: GlobeAsiaAustraliaIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
    {
      name: "よくあるご質問",
      href: "app/faq/common",
      icon: QuestionMarkCircleIcon,
      current: false,
      roles: ["owner", "member", "manager"],
      plans: ["free", "single", "multiple", "entry", "standard", "advance"],
    },
  ]);

  const router = useRouter();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (router && currentUser) {
      const cloneNavigation = [...navigation];
      const filteredNavigation = cloneNavigation
        .map((nav) => {
          let newNav = { ...nav };
          if (nav.children) {
            newNav.children = nav.children.filter((child) => {
              return (
                child.roles.includes(currentUser.authority) &&
                child.plans.includes(currentUser.plan)
              );
            });
          }
          return newNav;
        })
        .filter((nav) => {
          return (
            nav.roles.includes(currentUser.authority) &&
            nav.plans.includes(currentUser.plan) &&
            (nav.children === undefined || nav.children.length > 0)
          );
        });

      const tempNavigation = filteredNavigation.map((nav) => {
        let current =
          nav.href === "/app"
            ? router.pathname === nav.href
            : router.pathname.includes(nav.href);
        let children = nav.children?.map((child) => {
          let childCurrent = router.pathname.includes(child.href);
          if (childCurrent) current = true; // if child is current, parent should also be current
          return {
            ...child,
            current: childCurrent,
          };
        });
        return {
          ...nav,
          current,
          children,
        };
      });

      setSidebarOpen(false);
      setNavigation(tempNavigation);
    }
  }, [router, currentUser]);

  const toast = useToast();
  const repository = newAuthRepository(client);
  const setCurrentUser = useSetRecoilState(currentUserState);
  const logout = async () => {
    await repository.signOut();
    toast.success("ログアウトしました");
    setCurrentUser(null);
    await router.push("/signin");
  };

  return (
    <div className={`h-full`}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Link href={"/"}>
                      <Image
                        src={"/images/logo.png"}
                        alt={""}
                        width={144}
                        height={44}
                      />
                    </Link>
                  </div>
                  <nav className="mt-5 px-2">
                    <div className={"space-y-1"}>
                      {navigation.map((item, index) => (
                        <div key={index}>
                          {!item.children ? (
                            <Link
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-gray-500"
                                    : "text-gray-400 group-hover:text-gray-500",
                                  "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ) : (
                            <Disclosure
                              as="div"
                              defaultOpen={item.current}
                              key={item.current ? "open" : "closed"}
                            >
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      item.current
                                        ? "bg-gray-50"
                                        : "hover:bg-gray-50",
                                      "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 text-gray-700"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    <ChevronRightIcon
                                      className={classNames(
                                        open
                                          ? "rotate-90 text-gray-500"
                                          : "text-gray-400",
                                        "ml-auto h-5 w-5 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel
                                    as="ul"
                                    className="mt-1 px-2"
                                  >
                                    {item.children?.map((subItem) => (
                                      <li key={subItem.name}>
                                        <Disclosure.Button
                                          as="a"
                                          href={subItem.href}
                                          className={classNames(
                                            subItem.current
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                          )}
                                        >
                                          <subItem.icon
                                            className={classNames(
                                              item.current
                                                ? "text-gray-500"
                                                : "text-gray-400 group-hover:text-gray-500",
                                              "mr-3 flex-shrink-0 w-5"
                                            )}
                                            aria-hidden="true"
                                          />
                                          {subItem.name}
                                        </Disclosure.Button>
                                      </li>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className={"border-t mt-5 pt-5"}>
                      {subNavigation.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    <div className={"flex mt-10"}>
                      <button
                        className={
                          "flex items-center hover:bg-gray-100 rounded py-3 px-4 border"
                        }
                        onClick={() => {
                          logout();
                        }}
                      >
                        <ArrowLeftOnRectangleIcon className={"w-5 mr-2"} />
                        ログアウト
                      </button>
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={`hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col ${css.sidebar}`}
      >
        {/* GroupChats component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <Link href={"/"} className={"hover:opacity-60"}>
                <Image
                  src={"/images/logo.png"}
                  alt={""}
                  width={144}
                  height={44}
                />
              </Link>
            </div>
            <nav className="mt-5 flex-1 bg-white px-2">
              <div className={"space-y-2"}>
                {navigation.map((item, index) => (
                  <div key={index}>
                    {!item.children ? (
                      <Link
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure
                        as="div"
                        defaultOpen={item.current}
                        key={item.current ? "open" : "closed"}
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? "bg-gray-50"
                                  : "hover:bg-gray-50",
                                "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 text-gray-700"
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {item.name}
                              <ChevronRightIcon
                                className={classNames(
                                  open
                                    ? "rotate-90 text-gray-500"
                                    : "text-gray-400",
                                  "ml-auto h-5 w-5 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul" className="mt-1 px-2">
                              {item.children?.map((subItem) => (
                                <li key={subItem.name}>
                                  <Disclosure.Button
                                    as="a"
                                    href={subItem.href}
                                    className={classNames(
                                      subItem.current
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    )}
                                  >
                                    <subItem.icon
                                      className={classNames(
                                        item.current
                                          ? "text-gray-500"
                                          : "text-gray-400 group-hover:text-gray-500",
                                        "mr-3 flex-shrink-0 w-5"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {subItem.name}
                                  </Disclosure.Button>
                                </li>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                  </div>
                ))}
              </div>
              <div className={"border-t mt-5 pt-5"}>
                {subNavigation.map((item, index) => (
                  <Link
                    href={item.href}
                    key={index}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className={"flex mt-10"}>
                <button
                  className={
                    "flex items-center hover:bg-gray-100 rounded py-3 px-4 border text-sm"
                  }
                  onClick={() => {
                    logout();
                  }}
                >
                  <ArrowLeftOnRectangleIcon className={"w-5 mr-2"} />
                  ログアウト
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64 h-full">
        <div
          className={`fixed top-0 left-0 right-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden ${css.menuButton}`}
        >
          <button
            type="button"
            className="text-center focus:outline-none"
            onClick={() => setSidebarOpen(true)}
          >
            <Bars3Icon className="h-6 w-6 mx-auto -mb-2" aria-hidden="true" />
            <span className={"text-[10px] -mr-0.5 font-helvetica"}>MENU</span>
          </button>
        </div>
        <div className="flex-1 h-full">
          <div className="py-6 h-full">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 h-full pt-10 md:pt-0">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
